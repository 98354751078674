import { MapPinIcon } from '@heroicons/react/24/outline'
import { flag } from 'country-emoji'
import AnchorIcon from '~/icons/anchor-icon'
import TerminalIcon from '~/icons/terminal-icon'
import type { Suggestion } from '~/routes/fullstack-components.suggestions'

export const LabelFormatter = (comboboxValue: Suggestion) => {
  const getSubtitle = (type: string) => {
    switch (type) {
      case 'country':
        return 'All regions & terminals'
      case 'locode':
        return 'All terminals'
      case 'subdivision':
        return 'All regions'
      case 'terminal':
        return 'Terminal'
      case 'address':
        return 'Address'
      case 'geo':
        return 'Coordinate'
    }
  }
  return (
    <div className="flex flex-row place-items-center">
      {comboboxValue.type === 'country' && <div className="mt-2 h-6 w-6">{flag(comboboxValue.value)}</div>}
      {comboboxValue.type === 'subdivision' && <div className="mt-2 h-6 w-6">{flag(comboboxValue.value?.split('-')[0] || '')}</div>}
      {comboboxValue.type === 'locode' && (
        <div className="mr-2 mt-2">
          <AnchorIcon className="h-6 w-6" />
        </div>
      )}
      {comboboxValue.type === 'terminal' && (
        <div className="mr-2 mt-2">
          <TerminalIcon className="h-6 w-6" />
        </div>
      )}
      {(comboboxValue.type === 'address' || comboboxValue.type === 'geo') && (
        <div className="mr-2 mt-2">
          <MapPinIcon className="h-6 w-6" />
        </div>
      )}

      <div className="mx-1 flex-grow">
        <div>{comboboxValue.label}</div>
        <div className="mt-1 text-xs">{getSubtitle(comboboxValue.type)}</div>
      </div>

      {(comboboxValue.type === 'locode' || comboboxValue.type === 'subdivision') && (
        <div className="w-16 min-w-[4rem] items-center bg-brand-primary py-1 text-center align-middle text-sm text-white">{comboboxValue.value}</div>
      )}

      {comboboxValue.type === 'terminal' && comboboxValue.locode && (
        <div className="w-16 min-w-[4rem] items-center bg-brand-primary py-1 text-center align-middle text-sm text-white">{comboboxValue.locode}</div>
      )}
    </div>
  )
}
